import { createApp, reactive } from "vue"
import i18n from "./i18n"

import { useToast } from "vue-toastification"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import http from "./utils/http"
import 'semantic-ui-css/semantic.css'
import 'semantic-ui-css/semantic.js'

import App from "./App.vue"
import router from "./router"
import store from "./store"
import "@/assets/css/base.css"
import "@/assets/css/theme.less"
import "vant/lib/index.css"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import {
  NavBar,
  Button,
  Step,
  Steps,
  Dialog,
  CountDown,
  RollingText,
  Field,
  List,
  Cell,
  Pagination,
  Icon,
  Popover,
} from "vant"
// 导入打字机
import vuetyped from "@/components/vuetyped"

const app = createApp(App)
app.use(vuetyped)
app
  .use(NavBar)
  .use(Button)
  .use(Step)
  .use(Steps)
  .use(Dialog)
  .use(CountDown)
  .use(List)
  .use(Cell)
  .use(Field)
  .use(Icon)
  .use(Popover)
  .use(Pagination)
  .use(RollingText)

const toast = useToast()
const glo = reactive({
  id: ''
})
app.config.globalProperties.$global = glo
app.config.globalProperties.$toast = toast
app.config.globalProperties.$http = http

app.use(Toast, {
  rtl: false,
  icon: true,
  maxToasts: 3,
  timeout: 5000,
  draggable: true,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  closeButton: false,
  draggablePercent: 0.6,
  hideProgressBar: true,
  pauseOnFocusLoss: true,
  position: "top-center",
  showCloseButtonOnHover: false,
  transition: "Vue-Toastification__fade"
})

app.use(i18n)
app.use(store).use(router)
app.mount("#app")

var documentElement = document.documentElement
function callback() {
  var clientWidth = documentElement.clientWidth
  if (clientWidth <= 640) {
    documentElement.style.fontSize = clientWidth / 30 + "px"
  } else {
    documentElement.style.fontSize = clientWidth / 100 + "px"
  }
}

document.addEventListener("DOMContentLoaded", callback)
window.addEventListener(
  "orientationchange" in window ? "orientationchange" : "resize",
  callback
)

window.onload = function () {
  //设配ios浏览器的导航栏高度问题
  if (
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent)
  ) {
    document.querySelector("#app").style.height = window.innerHeight + "px"
  } else {
  }

  document.addEventListener("gesturestart", function (e) {
    e.preventDefault()
  })
  document.addEventListener("dblclick", function (e) {
    e.preventDefault()
  })
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  var lastTouchEnd = 0
  document.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
}

/**
 * 清除日志
 */
// if (process.env.VUE_APP_MODE === 'production' && !localStorage.getItem('debug')) {
//   window.console.log = () => { }
// }
