<template>
  <div class="swiper swiper-container" id="home-page-swiper">
    <van-button class="home-swiper-next img-btn">
      <img src="@/assets/img/btn_pull_down.png" />
    </van-button>
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="download-box">
          <div v-if="Config.android" @click="download(Config.android)">
            <img src="@/assets/img/btn_android.png" />
          </div>
          <div v-if="Config.android && Config.ios" style="width: 16px"></div>
          <div v-if="Config.ios" @click="download(Config.ios)">
            <img src="@/assets/img/btn_apple_store.png" />
          </div>
        </div>
        <div class="m-view-home">
          <Card
            id="card"
            class="center"
            v-if="showCard"
            @selected="handleSelected"
            @again="handleAgain"
          ></Card>
          <Transition>
            <div
              class="quertion-wrapper center flex-column vc"
              v-if="showQuestionInput"
            >
              <vuetyped
                :strings="[
                  'Please remain calm and focus on your problem first.',
                ]"
                :loop="false"
                :smart-backspace="false"
                :typeSpeed="30"
                :showCursor="false"
                @onComplete="showSecondSentence = true"
              >
                <div class="typing"></div>
              </vuetyped>

              <vuetyped
                v-if="showSecondSentence"
                :strings="['Now, please state your problem']"
                :loop="false"
                :smart-backspace="false"
                :typeSpeed="30"
                :showCursor="false"
                @onComplete="showInput = true"
              >
                <div class="typing"></div>
              </vuetyped>

              <!-- v-if="showInput" -->
              <van-field
                v-model="question"
                center
                class="question-input xs:!w-[90vw]"
                placeholder="Please enter your question"
                v-if="false"
                @keyup.enter="handleSend"
              >
                <template #button>
                  <img src="@/assets/img/send-btn.png" @click="handleSend" />
                </template>
              </van-field>
            </div>
          </Transition>

          <Transition>
            <video
              :src="
                isMobile
                  ? require('@/assets/video/home-video-loop-m.mp4')
                  : require('@/assets/video/home-video-loop.mp4')
              "
              muted
              loop
              preload="auto"
              autoplay
              class="loop-video center"
              ref="loopVideoRef"
              x-scroll="false"
              v-show="showBgVideo"
              x5-playsinline="true"
              playsinline="true"
              webkit-playsinline="true"
              :controls="false"
              id="bg-loop-video"
            ></video>
          </Transition>

          <Transition>
            <video
              :src="
                isMobile
                  ? require('@/assets/video/Viedo_03_Fade-m.mp4')
                  : require('@/assets/video/Viedo_03_Fade.mp4')
              "
              muted
              preload="auto"
              autoplay="none"
              class="loop-video center"
              ref="flyCardVideoRef"
              x-scroll="false"
              v-show="showFlyCard"
              x5-video-player-fullscreen="false"
              x5-playsinline="true"
              playsinline="true"
              webkit-playsinline="true"
              :controls="false"
            ></video>
          </Transition>

          <Transition>
            <video
              :src="
                isMobile
                  ? require('@/assets/video/Viedo_04_Fade-m.mp4')
                  : require('@/assets/video/Viedo_04_Fade.mp4')
              "
              muted
              loop
              preload="auto"
              autoplay="none"
              class="loop-video center"
              ref="divineVideoRef"
              x-scroll="false"
              v-show="showDivineLoopVideo"
              x5-video-player-fullscreen="false"
              x5-playsinline="true"
              playsinline="true"
              webkit-playsinline="true"
              :controls="false"
            ></video>
          </Transition>
          <Result
            v-if="showResult"
            @close="showResult = false"
            :quertionFromParent="question"
            :cardsFromParent="cards"
          ></Result>
        </div>
      </div>
      <div class="swiper-slide">
        <WorldView :active="currentSlide == 1"></WorldView>
      </div>
      <div class="swiper-slide">
        <Hero></Hero>
      </div>
      <div class="swiper-slide">
        <Intro></Intro>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import Card from '@/views/Card.vue';
import Swiper, { Navigation, Mousewheel } from 'swiper';
import Intro from './Intro.vue';
import WorldView from './WorldView.vue';
import Hero from './Hero.vue';
import Result from './Result.vue';
import useStore from '@/store/modules/main';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { nextTick } from 'vue';
import i18n from '../i18n';
import { Config } from '@/store/config';
import useGlobal from '@/store/modules/global';
import http from '@/utils/http';
import router from '@/router';
Swiper.use([Mousewheel, Navigation]);

const toast = useToast();
const state = useStore();
const global = useGlobal();
const route = useRoute();
const entryVideoRef = ref();
const loopVideoRef = ref();
const flyCardVideoRef = ref();
const divineVideoRef = ref();
const showSecondSentence = ref(false);
const showInput = ref(false);
const showDivineLoopVideo = ref(false);
const showFlyCard = ref(false);
const question = ref('');
const showCard = ref(false);
const showResult = ref(false);
const showQuestionInput = ref(false);
const showBgVideo = ref(false);
const isMobile = ref(false);
const currentSlide = ref(0);
const cards = ref();
const showFooter = ref(false);
var swiper;
onMounted(() => {
  isMobile.value = document.body.clientWidth <= 768;
  swiper = new Swiper('#home-page-swiper', {
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: true,
    speed: 1000,
    navigation: {
      nextEl: '.home-swiper-next',
    },
    on: {
      beforeSlideChangeStart: function (obj) {
        //console.log(obj);
      },
      slideChange: function ({ activeIndex }) {
        currentSlide.value = activeIndex;
      },
    },
  });

  if (route.query?.token) {
    http
      .post('/api/guest/onceLogin', {
        token: route.query?.token,
      })
      .then((res) => {
        if (!res) return false;

        if (res.data.status.code != 10200) {
          toast.error(res.data.status.msg);
          return false;
        }

        localStorage.setItem('authorization', res.data.data.auth);
        global.setUserInfo(res.data.data);
        router.push('/purchase');
      });
  }
});

const handleAgain = () => {
  showQuestionInput.value = true;
  showSecondSentence.value = false;
  showInput.value = false;
  showCard.value = false;
  question.value = '';
};

watch(showResult, (val) => {
  if (val) {
    //swiper.mousewheel.disable();
  } else {
    swiper.mousewheel.enable();
  }
});

watch(
  () => state.entryVideoStatus,
  (val) => {
    if (val == 'ended') {
      showBgVideo.value = true;
      nextTick(() => {
        loopVideoRef.value?.play();
      });
    }
  },
  { immediate: true }
);

watch(
  () => showBgVideo.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        showQuestionInput.value = true;
      }, 1000);
    }
  },
  { immediate: true }
);

const handleSend = () => {
  if (question.value.length < 5) {
    toast.error(i18n.global.t('divine_too_short'));
    return false;
  }

  showQuestionInput.value = false;
  //设置延时执行以衔接淡出动画，不需要可以去掉
  setTimeout(() => {
    //清空问题输入，以备再次使用
    showBgVideo.value = false;
    showFlyCard.value = true;
    if (flyCardVideoRef.value) {
      flyCardVideoRef.value?.play();
      flyCardVideoRef.value.ontimeupdate = (res) => {
        //if (flyCardVideoRef.value.currentTime >= 9)
        {
          !showCard.value && (showCard.value = true);
          flyCardVideoRef.value.ontimeupdate = null;
          //飞牌视频播完一次后就remove掉，后续不再播放
          flyCardVideoRef.value.remove();
          divineVideoRef.value?.play();
          showDivineLoopVideo.value = true;
          showFlyCard.value = false;
        }
      };
    }
  }, 1000);
};

const handleSelected = (param) => {
  cards.value = param;
  showResult.value = true;
};
const download = (url) => {
  if (!url) return false;

  window.location.href = url;
};
</script>

<style lang="less" scoped>
#card {
  position: fixed;
  z-index: 9;
  top: 68%;
  left: 50%;
}

#home-page-swiper {
  width: 100vw;
  flex-grow: 1;
  .home-swiper-next {
    position: absolute;
    bottom: 0%;
    left: 50%;
    margin-left: -21px;
    // transform: translate(-50%, 0%);
    z-index: 9;
    animation: bounce_btn linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    img {
      width: 42px;
    }
  }

  .home-swiper-next.swiper-button-disabled {
    display: none;
  }
}

@keyframes bounce_btn {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(20%);
  }

  100% {
    transform: translateY(0%);
  }
}

.m-view-home {
  position: relative;
  width: 100%;
  height: 100%;

  .entry-video {
    width: 100vw;
    z-index: 0;
  }

  .loop-video {
    width: 100vw;
    z-index: 0;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 2s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .quertion-wrapper {
    position: absolute;
    z-index: 2;
    top: 46%;
    min-height: 200px;

    .typing {
      word-break: break-all;
      font-size: 27px;
      font-weight: 400;
      color: #faf4e9;
      line-height: 50px;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
    }

    .question-input {
      padding: 0;
      width: 793.8px;
      height: 57.3px;
      margin-top: 105px;
      background-color: initial;
      background-image: url('@/assets/img/input-bg.png');
      background-size: 100% 100%;
      padding: 0 33px;
      padding-right: 50px;
      font-size: 27px;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.77);
      --van-field-input-text-color: #faf4e9;
      --van-field-placeholder-text-color: rgba(196, 189, 177, 0.3);
      --van-cell-background: rgba(0, 0, 0, 0);

      :deep(.van-field__button img) {
        width: 41.5px;
        height: 34px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #card {
    position: fixed;
    z-index: 9;
    top: 68%;
    left: 50%;
  }

  #home-page-swiper {
    height: 100vh;
    width: 100vw;

    .home-swiper-next {
      position: absolute;
      bottom: 0%;
      left: 50%;
      margin-left: -21px;
      // transform: translate(-50%, 0%);
      z-index: 9;
      animation: bounce_btn linear;
      animation-duration: 1s;
      animation-iteration-count: infinite;

      img {
        width: 42px;
      }
    }

    .home-swiper-next.swiper-button-disabled {
      display: none;
    }
  }

  .m-view-home {
    position: relative;
    width: 100%;
    height: 100%;

    .entry-video {
      width: 100vw;
      z-index: 0;
    }

    .loop-video {
      width: 100vw;
      z-index: 0;
    }

    .quertion-wrapper {
      top: 46%;
      min-height: 200px;
      width: 629px;

      .typing {
        font-size: 22px;
        line-height: 40px;
        text-shadow: 0px 0px 9px rgba(255, 255, 255, 0.77);
      }

      .question-input {
        height: 72px;
        margin-top: 84px;

        padding: 0 25px;
        padding-right: 40px;
        font-size: 22px;
        text-shadow: 0px 0px 9px rgba(255, 255, 255, 0.77);

        :deep(.van-field__button img) {
          width: 34px;
          height: 29px;
        }
      }
    }
  }
}
.download-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  width: 100%;
  bottom: 12%;
  z-index: 10;
}
.download-box img {
  width: 200px;
}
</style>
