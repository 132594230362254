<template>
  <div style="display: flex; gap: 10px">
    <slot name="default" :connected="connected" :address="address">
      <div
        class="ui button !flex items-center justify-center !mr-0 xs:!text-base xs:!py-2 !text-white !px-4 w-full !bg-[#4980bf]"
        @click="selectWallets"
        v-if="!connected"
      >
        <img
          src="@/assets/img/task/icon_wallet_small.png"
          class="w-6 mr-2"
          alt=""
        />
        Connect Wallet
      </div>
      <div
        v-else
        class="ui button !mr-0 !flex items-center !py-2 !px-0 !text-[#4980bf] xs:!text-base xs:!py-2"
        style="background: transparent !important"
      >
        <img :src="walletLogo" class="h-8 mr-4 xs:mr-2 xs:h-6" />
        {{ address }}
        <i
          class="sign-out icon !ml-4 text-[#4980bf] cursor-pointer !h-full xs:!text-sm !inline-flex items-center xs:!ml-2"
          @click="disconnect"
        ></i>
      </div>
    </slot>
  </div>

  <div
    class="ui mini basic modal aptos-selected-wallet xs:!w-2/3"
    id="selectWallets"
  >
    <div class="ui header xs:!text-lg">Connect Wallet</div>
    <div class="content">
      <div class="ui middle aligned selection list">
        <div
          v-for="(list, index) in wallets.filter((item) =>
            VISIBLE_WALLET.includes(item.name)
          )"
          class="item xs:!text-base xs:!my-2 xs:!p-2"
          @click="!isLoading && handleSelectedWallet(list)"
          :key="index"
        >
          <template v-if="list">
            <div v-if="list.readyState == 'Installed'" class="installed">
              <span class="ui green label mini">{{ list.readyState }}</span>
            </div>
            <img class="ui avatar image xs:!size-6" :src="list.icon" />
            <div class="content font-bold">{{ list.name }}</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWalletUtils } from '@/hooks/useWalletUtils.js';
import { ref, inject, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import http from '@/utils/http';
const { assignWallet, bindWallet } = useWalletUtils();

const {
  connected,
  wallets,
  connect,
  account,
  signMessage,
  wallet,
  disconnect,
  isLoading,
} = inject('WalletContext');

const VISIBLE_WALLET = [
  'Petra',
  'OKX Wallet',
  'Pontem Wallet',
  'Pontem',
  'Bitget Wallet',
  'Nightly',
];
const toast = useToast();
const address = computed(() => {
  return connected.value ? assignWallet(account.value.address) : '';
});
const walletLogo = computed(() => {
  return connected.value
    ? wallet.value.icon
    : require(`@/assets/img/task/icon_wallet_small.png`);
});

onMounted(async () => {
  const walletName = localStorage.getItem('AptosWalletName');
  if (walletName && !connected.value) {
    try {
      await connect(walletName);

      if (!localStorage.getItem('authorization')) {
        disconnect();
        return;
      }
      const bindStatusRes = await http.post('/api/user/getBindStatus', {
        address: account?.value.address,
      });

      if (bindStatusRes?.data?.status?.code !== 10200) {
        disconnect();
        return;
      }
    } catch (err) {
      console.log('重连错误', err);
    }
  }
});

const selectWallets = () => {
  // eslint-disable-next-line no-undef
  $('.ui.modal.aptos-selected-wallet')
    .modal({
      blurring: true,
      observeChanges: true,
    })
    .modal('show');
};

const handleSelectedWallet = async (wallet) => {
  try {
    if (!connected.value) {
      await connect(wallet.name);
    }

    const bindStatusRes = await http.post('/api/user/getBindStatus', {
      address: account.value?.address,
    });

    if (bindStatusRes?.data?.status?.code === 10200) {
      $('.ui.modal.aptos-selected-wallet').modal('hide');
      return;
    }

    const payload = {
      message:
        'Welcome to the Castile platform’s wallet management process. By clicking to sign in, you are initiating the linking of your wallet address to your Castile account as a deposit wallet. This request will not trigger a blockchain transaction or cost any gas fees.',
      nonce: Date.now(),
    };
    let sign = await signMessage(payload);
    if (!sign) {
      $('.ui.modal.aptos-selected-wallet').modal('hide');
      return;
    }

    let signature, fullMessage;
    if (wallet.name.indexOf('Pontem') != -1) {
      signature = Buffer.from(sign.result.signature).toString('base64');
      fullMessage = sign.result.fullMessage;
    } else {
      signature = sign.signature;
      fullMessage = sign.fullMessage;
    }

    await bindWallet(signature, account, fullMessage, wallet.name, 'Aptos');
  } catch (error) {
    console.log(error);
    disconnect();
  }
  $('.ui.modal.aptos-selected-wallet').modal('hide');
};

defineExpose({
  selectWallets,
  account,
  connected,
  disconnect,
});
</script>

<style lang="less" scoped>
.ui.header {
  text-align: center;
  padding-top: 1.5rem;
  font-size: 1.6rem;
}
.ui.mini.modal {
  border-radius: clamp(0px, calc(4px * 9), 44px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-color: #191a1a;
  overflow: hidden;
}
.ui.basic.modal > .content {
  max-height: 60vh;
  overflow-y: auto;
  padding: 0.8rem;
  padding-top: 0;
}
.ui.selection.list.list > .item,
.ui.selection.list > .item {
  column-gap: 12px;
  padding: 1rem 0.8rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.02);
  border-radius: calc(4px * 4);
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #e4e7e7;
  display: flex;
  position: relative;
  align-items: center;
}
.installed {
  position: absolute;
  right: 0.5rem;
}
.ui.selection.list.list > .item:hover,
.ui.selection.list > .item:hover {
  background: rgba(255, 255, 255, 0.05);
  color: #e4e7e7;
}
.ui.selection.list > .item:first-of-type {
  margin-top: 0;
}
.ui.selection.list > .item:last-of-type {
  margin-bottom: 0;
}
.ui.selection.list > .item .content {
  font-family: auto;
}
.ui.mini.label,
.ui.mini.labels .label {
  font-family: auto;
}
.bd {
  border: 1px solid red !important;
}
@media only screen and (min-width: 1500px) {
  .ui.mini.modal {
    width: 20rem;
  }
}
</style>
